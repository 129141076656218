<template>
  <div class="background py-16">
    <CompanyAcceptInvite />
  </div>
</template>

<script>
import { mapState } from "vuex";

import CompanyAcceptInvite from "@/components/elements/invites/company-accept-invite/CompanyAcceptInvite";

export default {
  name: "DocumentManagementCompanyAcceptInvite",

  components: {
    CompanyAcceptInvite,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (this.auth.loggedIn) {
      this.$router.push({ name: "DashboardInbox" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
